// angular import
import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// project import
import { AuthenticationService } from 'src/app/@theme/services/authentication.service';
import { ApiService } from 'src/app/core/services/api.service';
import { CrudService } from 'src/app/core/services/crud.service';
import { GtoolsService } from 'src/app/core/services/gtools.service';
import { ChangePasswordComponent } from 'src/app/pages/change-password/change-password.component';

@Component({
  selector: 'app-nav-right',
  templateUrl: './toolbar-right.component.html',
  styleUrls: ['./toolbar-right.component.scss']
})
export class NavRightComponent {
  // public props
  @Output() HeaderBlur = new EventEmitter();
  currentUser: any;
  profileImage: any = "assets/images/user/user-retina.png";

  clickEventsubscription:Subscription;

  // constructor
  constructor(
    private translate: TranslateService,
    private _gt: GtoolsService,
    private _auth: AuthenticationService,
    private _crud: CrudService,
    private _api: ApiService,
    public dialog: MatDialog
  ) {
    translate.setDefaultLang('en');
    this.currentUser = this._auth.getCurrentUser();
    this.getUser();

    this.clickEventsubscription = this._gt.getChangeProfil().subscribe(()=>{
      console.log(this.clickEventsubscription);
      this.getUser();
    });
  }

  // public method
  // user according language change of sidebar menu item
  useLanguage(language: string) {
    this.translate.use(language);
  }

  getUser() {
    this._crud.getPromise("/api/entity/User/" + this.currentUser.id).then((res: any) => {
      if (res['data'].photo) {
        this._api.getData('/api/secured/image/' + res['data'].photo).subscribe({
          next: (res: any) => {
            this.profileImage = res;
          },
          error: (err: any) => {
            console.log(err);
          }
        });
      }
    });
  }

  headerBlur() {
    this.HeaderBlur.emit();
  }

  modalPassword() {
    this.dialog.open(ChangePasswordComponent, {data: {}});
  }

  // user Logout
  logout() {
    this._auth.logout();
  }

}
