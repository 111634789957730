<mat-drawer-container class="drawer-container" [dir]="direction">
  <mat-drawer
    #sidebar
    class="pc-sidebar"
    [mode]="modeValue"
    opened
    [ngClass]="{
      compact: currentLayout === 'compact',
      horizontal: currentLayout === 'horizontal'
    }"
  >
    <nav>
      <div class="navbar-wrapper">
        <div class="m-header">
          <a href="/">
            <span class="short-logo">
              <img src="assets/images/logo/favicon.png" alt="user-image" class="logo-retina" />
            </span>
            <span class="long-logo">
              <img src="assets/images/logo/petroserv-logo.png" alt="user-image" class="logo-retina-long" />
            </span>
          </a>
        </div>
        @if (currentLayout === 'vertical') {
          <app-vertical-menu [menus]="menus"></app-vertical-menu>
        }
        @if (currentLayout === 'compact') {
          <!-- <app-compact-menu [menus]="menus"></app-compact-menu> -->
          <app-compact-menu ></app-compact-menu>
        }
      </div>
    </nav>
  </mat-drawer>
  <div>
    <app-nav-bar
      [ngClass]="{
        'horizontal-navbar': currentLayout === 'horizontal'
      }"
    ></app-nav-bar>
    @if (currentLayout === 'horizontal') {
      <app-horizontal-menu
        [menus]="menus"
        [ngClass]="{
          'horizontal-menu': currentLayout === 'horizontal'
        }"
      ></app-horizontal-menu>
    }
    <div class="app-container">
      <app-breadcrumb [dashboard]="true"></app-breadcrumb>
      <div class="main-body">
        <div class="page-wrapper">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</mat-drawer-container>
<app-configuration></app-configuration>
