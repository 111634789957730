@if (!item.hidden) {
  <!-- <li class="nav-item coded-menu-caption">
    <label>{{ item.title | translate }}</label>
  </li> -->
  @for (item of item.children; track item) {
    @if (item.type === 'group') {
      <app-menu-group-compact [item]="item"></app-menu-group-compact>
    } @else if (item.type === 'collapse') {
      <app-menu-collapse-compact [item]="item"></app-menu-collapse-compact>
    } @else if (item.type === 'item') {
      <app-menu-item-compact [item]="item"></app-menu-item-compact>
    }
  }
}
