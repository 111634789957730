@if (!item.hidden) {
  <li data-username="able-pro dashboard" class="nav-item coded-hasmenu" [routerLinkActive]="['active']">
    <a [routerLinkActive]="['active']" class="nav-link" (click)="navCollapse($event)">
      @if (item.icon) {
        <span class="coded-micon">
          <svg class="pc-icon">
            <use attr.xlink:href="assets/fonts/custom-icon.svg{{ item.icon }}"></use>
          </svg>
        </span>
      }
      <span class="coded-mtext">{{ item.title | translate }} </span>
    </a>
    <ul class="coded-submenu" [routerLinkActive]="['active']" [ngClass]="item.classes!">
      @for (item of item.children; track item.id) {
        @if (item.type === 'group') {
          <app-menu-group-vertical [item]="item"></app-menu-group-vertical>
        } @else if (item.type === 'collapse') {
          <app-menu-collapse [item]="item"></app-menu-collapse>
        } @else if (item.type === 'item') {
          <app-menu-item [item]="item"></app-menu-item>
        }
      }
    </ul>
  </li>
}
