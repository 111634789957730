// angular import
import { Component, OnInit } from '@angular/core';

// project import
import { ThemeLayoutService } from 'src/app/@theme/services/theme-layout.service';
import { HORIZONTAL, VERTICAL, COMPACT } from 'src/app/@theme/const';
import { AuthenticationService } from 'src/app/@theme/services/authentication.service';

@Component({
  selector: 'app-nav-left',
  templateUrl: './toolbar-left.component.html',
  styleUrls: ['./toolbar-left.component.scss']
})
export class NavLeftComponent implements OnInit {
  showToggleMenu: boolean = true;
  showLogo: boolean = false;
  currentUser: any;
  role: any;
  // constructor
  constructor(private layoutService: ThemeLayoutService, private _auth: AuthenticationService) {
    this.currentUser = this._auth.getCurrentUser();
  }

  ngOnInit() {
    this.showLogo = false;

    this.role = this.currentUser.roles[0];
    if (this.role == 'CLIENT') {
      this.showLogo = true;
    }

    this.layoutService.layout.subscribe((res) => {
      
      if (res === VERTICAL) {
        this.showToggleMenu = true;
      }
      if (res === HORIZONTAL) {
        this.showToggleMenu = false;
      }
      if (res === COMPACT) {
        this.showToggleMenu = true;
      }
      
    });
  }

  // public method
  toggleMenu() {
    this.layoutService.toggleSideDrawer();
  }
}
