export const environment = {
    CLIENT_ID: '86b4a2e98ddec19e0f429b908fcf5090',
    CLIENT_SECRET: '81f5f6031bfda677e1731fefd96fd1e6155b6de56badfb8fcc742fb19a2342359cb5ed449ec250804ac670d472321a1102ede6a488ee3508e7de826545c7f569',


    authURI: '/token',
    entityURI: '/api/entity/',
    uploadPath: '/uploads/',
    profilPath: '/../files/profiles/',
    securedPhoto: '/insecpages/photos/',
    sitePath: '/../files/sites/',
    documentPath: '/../files/',
    imgPath: '/assets/img/',
    securedImgPath: '/insecpages/images/',
    securedFilePath: '/insecpages/files/',
    tinyMCEKey: 'qzfi2lqwbcg5yruqvgxx8vxze3vtqt4csnyax8qgw5d4gslg',
    lifeRulesPageCount: 18,
    defaultAvatar: '/assets/img/faces/default-avatar.png',
    title: 'Retina Service Management Software',
    version: 'v3.0.18'
}