import { Component, OnInit } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { GtoolsService } from 'src/app/core/services/gtools.service';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  data: any;
  params: any;
  moduleName: any;

  constructor(
    public _gt: GtoolsService
  ) { }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params.value;
    this.moduleName = this.params.context.componentParent.moduleName;
  }

  ngOnInit(): void {
    
  }

  openViewDialog() {
    this.params.context.componentParent.openDialog(this.params, 'view');
  }

  openEditDialog() {
    this.params.context.componentParent.openDialog(this.params, 'edit');
  }

  deleteRow() {
    this.params.context.componentParent.deleteRow(this.params);
  }

  // Custom actions from action column AG-GRID
  customAction(action: any) {
    this.params.context.componentParent[`${action}`](this.params);
  }

  print() {
    this.params.context.componentParent.exportPDF(this.params);
  }
}
