import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionsComponent } from './actions.component';
import { SharedModule } from 'src/app/demo/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { AbilityModule } from '@casl/angular';
import { PureAbility } from '@casl/ability';



@NgModule({
  declarations: [
    ActionsComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
})
export class ActionsModule { }
