@if (!item.hidden) {
  <li class="nav-item coded-menu-caption">
    <label>{{ item.title | translate }}</label>
  </li>
  @for (item of item.children; track item.id) {
    @if (item.type === 'group') {
      <app-menu-group-vertical [item]="item"></app-menu-group-vertical>
    } @else if (item.type === 'collapse') {
      <app-menu-collapse [item]="item"></app-menu-collapse>
    } @else if (item.type === 'item') {
      <app-menu-item [item]="item"></app-menu-item>
    }
  }
}
