/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { GtoolsService } from './gtools.service';
import { firstValueFrom } from 'rxjs';

import swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(
    private _api: ApiService,
    public _gt: GtoolsService
  ) { }

  getPromise(uri: any) {
    const promise = new Promise<void>((resolve, reject) => {
      firstValueFrom(this._api.getData(uri))
        .then((res: any) => {
          resolve(res);
          this._gt.setLoading(false);
        },
        msg => { // Error
          reject(msg);
          this._gt.setLoading(false);
        });
    });
    return promise;
  }

  putPromise(uri: any, data: any, gridApi: any = null, params: any = null) {
    const promise = new Promise((resolve, reject) => {
      this._api.put(uri, data)
        .toPromise()
        .then(
          (res: any) => { // Success
            if (gridApi) {
              const id = params.node.id;
              const rowNode = gridApi.getRowNode(id);
              const data = res['data'][0];
              rowNode.setData(data);
              gridApi.redrawRows();
            }
            resolve(res);
          },
          msg => { // Error
            reject(msg);
            this._gt.setLoading(false);
          }
        );
    });
    return promise;
  }

  postPromise(uri: any, data: any, gridApi: any = null, params: any = null) {
    const promise = new Promise<void>((resolve, reject) => {
      firstValueFrom(this._api.post(uri, data))
        .then((res: any) => {
          const resData = res['data'][0];
          
          if (gridApi) {
            if (data.id !== undefined) {
              const rowNode = gridApi.getRowNode(params.node.id);
              rowNode.setData(resData);
            } else {
              gridApi.applyTransaction({ add: [resData], addIndex: undefined });
            }
            this._gt.setLoading(false);
          }
          resolve(res);
        },
        msg => { // Error
          reject(msg);
          this._gt.setLoading(false);
        });
    });
    return promise;
  }


  // Delete selected row
  deleteRow(params: any, entity: any, gridApi: any) {

    // If has child Many to Many => Delete is prohibited
    if (!this._gt.isElementDeletable(params.data)) {
      swal.fire({
        title: 'Unauthorized deletion',
        text: 'Unable to delete this item which is linked to other associated modules',
        buttonsStyling: false,
        customClass: {
          confirmButton: "mdc-button mdc-button--raised mat-mdc-raised-button mat-primary"
        }
      })
      return;
    }

    swal.fire({
      title: this._gt._translate('swal.deleteConfirmation'),
      text: this._gt._translate('swal.deleteWarning'),
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary m-r-5',
        cancelButton: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-warn'
      },
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'Cancel',
      buttonsStyling: false
    }).then((resultPop: any) => {
      if (resultPop.value) {
        const id = params.data.id;
        const uri = environment.entityURI + entity + '/' + id;
        this._api.delete(uri).subscribe((() => {
          this._gt.showNotification(this._gt._translate('swal.deleted'));
          gridApi.applyTransaction({ remove: [params.data] });
        }));
      }
    });
  }
}

