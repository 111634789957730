import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/@theme/services/authentication.service';
import { CrudService } from 'src/app/core/services/crud.service';
import { GtoolsService } from 'src/app/core/services/gtools.service';
import { SharedModule } from 'src/app/demo/shared/shared.module';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;
  hide = true;
  hide2 = true;
  currentUser: any;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    public _gt: GtoolsService,
    private fb: FormBuilder,
    private _auth: AuthenticationService,
    private _crud: CrudService
  ) {

  }

  ngOnInit(): void {
    this.currentUser = this._auth.getCurrentUser();

    const group = {
      password: [null, Validators.required],
      confirm_password: [null, Validators.required],
    }

    this.form = this.fb.group(group);
    this._gt.setLoading(false);
  }

  // convenience getter for easy access to form fields
  get f(): any { return this.form.controls; }

  save() {

    // Control required formControl
    if (!this._gt.isFormValid(this.form)) {
      return;
    }

    const saveForm = this._gt.getChangedFormFields(this.fb, this.form);
    saveForm.value['id'] = this.currentUser.id;

    if(saveForm.value['password'].length < 6) {
      this._gt.showNotification(this._gt._translate('Password is too short. Please enter 6 characters minimum'));
      return;
    }

    if(saveForm.value['password'] != saveForm.value['confirm_password']) {
      this._gt.showNotification(this._gt._translate('Passwords do not match'));
      return;
    }

    this._crud.postPromise('/api/save/User', saveForm.value,).then(res => {
      this._gt.showNotification(this._gt._translate('swal.updated'));
      
      this.dialogRef.close();
    });
  }
}
