<!-- <span type="button" class="event-tooltip float-end">
    <button mat-mini-fab >
        <i class="material-icons">more_vert</i>
    </button> -->
    <span class="tooltip-all-button">

        <!-- Print for Receipt -->
        <span *ngIf="('print'| able: moduleName) && moduleName == 'RECEIPTS'">
            <button (click)="print()" mat-mini-button color="accent" matTooltip="Print">
                <mat-icon>print</mat-icon>
            </button>&nbsp;
        </span>

        <!-- Read -->
        <span *ngIf="'read'| able: moduleName">
            <button (click)="openViewDialog()" mat-mini-button class="mat-view" matTooltip="View">
                <mat-icon>remove_red_eye</mat-icon>
            </button>&nbsp;
        </span>

        <!-- Update -->
        <span *ngIf="'update'| able: moduleName">
            <button (click)="openEditDialog()" mat-mini-button class="mat-edit" matTooltip="Edit">
                <mat-icon>edit</mat-icon>
            </button>&nbsp;
        </span>

        <!-- Delete -->
        <span *ngIf="'delete'| able: moduleName">
            <button (click)="deleteRow()" mat-mini-button class="mat-delete" matTooltip="Delete">
                <mat-icon>delete</mat-icon>
            </button>
        </span>

        <!-- Custom action buttons -->

        <span *ngIf="params.customActions !== undefined">
            <button *ngIf="params.customActions.rights | able: moduleName" matRipple type="button"
                (click)="customAction(params.customActions.action)" class="btn" [ngClass]="params.customActions?.color">
                <mat-icon>{{params.customActions?.icon}}</mat-icon>
            </button>
        </span>

    </span> 
<!-- </span> -->
