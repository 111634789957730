import { Navigation } from 'src/app/@theme/types/navigation';

export const menus: Navigation[] = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'Dashboard',
        title: 'Dashboard',
        type: 'item',
        classes: 'nav-item',
        url: '/dashboard',
        icon: '#custom-status-up',
        breadcrumbs: false
      },
      // {
      //   id: 'job',
      //   title: 'Job',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/job',
      //   icon: '#custom-notification-status',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'tankreceipt',
      //   title: 'Tank Receipt',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/tank-receipt',
      //   icon: '#custom-fatrows',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'bunker',
      //   title: 'Bunker',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/tank-receipt',
      //   icon: '#custom-story',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'pipline',
      //   title: 'Pipeline Tranfert',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/tank-receipt',
      //   icon: '#custom-direct-inbox',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'equipment',
      //   title: 'Equipment',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/equipment',
      //   icon: '#custom-document-filter',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'customer',
      //   title: 'Customer',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/customer',
      //   icon: '#custom-user-square',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'report',
      //   title: 'Report',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/report',
      //   icon: '#custom-presentation-chart',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'schedule',
      //   title: 'Schedule',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/schedule',
      //   icon: '#custom-calendar-1',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'config',
      //   title: 'System Settings',
      //   type: 'item',
      //   classes: 'nav-item',
      //   url: 'features/configuration',
      //   icon: '#custom-setting-2',
      //   breadcrumbs: false
      // },
      // {
      //   id: 'admin',
      //   title: 'Advanced Settings',
      //   type: 'collapse',
      //   icon: '#custom-shield',
      //   children: [
      //     {
      //       id: 'user',
      //       title: 'User',
      //       type: 'item',
      //       url: '/advanced/user',
      //       breadcrumbs: false
      //     },
      //     {
      //       id: 'role',
      //       title: 'Role',
      //       type: 'item',
      //       url: '/advanced/role',
      //       breadcrumbs: false
      //     },
      //     {
      //       id: 'module',
      //       title: 'Modules',
      //       type: 'item',
      //       url: '/advanced/module',
      //       breadcrumbs: false
      //     }
      //   ]
      // }
    ]
  },
];
