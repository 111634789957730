/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
// import { saveAs } from 'file-saver';
import { ApiService } from './api.service';

import swal from 'sweetalert2';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GtoolsService {

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  durationInSeconds = 2;
  private subject = new Subject<any>();

  constructor(
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private _api: ApiService
  ) { }


  sendChangeProfil(value: any) {
    this.subject.next(value);
  }
  // get click event
  getChangeProfil(): Observable<any>{ 
    return this.subject.asObservable();
  }

  _translate(key:any) {
    return this.translate.instant(key);
  }

  /** Add action column on aggrid */
  addActionColumn(moduleName: any, actions: any, columnDefs: any, params: any) {
    const action = JSON.parse(JSON.stringify(actions.action)); // cloned data object for nested array
    action.cellRendererFramework = actions.action.cellRendererFramework;
    
    columnDefs.push(action);
  }

  agGridHeaderSetter(moduleName: any, coldefs: any) {

    coldefs.forEach((v: any, i: any) => {
      if (v.headerName != "#" && v.headerName != "Action") {
        let mName = moduleName ? moduleName + ".crud." : "";
        coldefs[i].headerName = this._translate(mName + v.headerName);
      }
    });
  }

  checkDirtyClose(thisObject: any, form: any, crud: boolean): void {

    let dirty: boolean = false;

    Object.keys(form.controls).forEach(name => {
      let currentControl = form.controls[name];

      if (currentControl.dirty && currentControl.touched) {
        dirty = true;
      }
    });
    if (dirty) {
      swal.fire({
        title: this._translate('swal.unsavedTitle'),
        text: this._translate('swal.unsavedQuestion'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-primary m-r-5',
          cancelButton: 'mdc-button mdc-button--raised mat-mdc-raised-button mat-warn'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value == true) {
          dirty = false;
          thisObject.save();
          //thisObject.close();
        } else {
          if (crud) {
            thisObject.isCrud = false;
            thisObject.isCrudChange.emit(thisObject.isCrud);
          } else {
            thisObject.dialogRef.close();
          }
          this.showNotification(this._translate("swal.unsavedData"));
        }
      })
    } else {
      if (crud) {
        thisObject.isCrud = false;
        thisObject.isCrudChange.emit(thisObject.isCrud);
      } else {
        thisObject.dialogRef.close();
      }
    }
  }

  // Format boolean true or false
  boolFormatter(params: any) {
    let formattedCell = '';
    if (params.value == 1 || params.value == true) {
      formattedCell = '<div class="flex-shrink-0"><div class="avatar avatar-s bg-success-50 text-success-500"><i class="ti ti-check f-20"></i></div></div>';
    } else if(params.value == false || params.value == 0) {
      formattedCell = '<div class="flex-shrink-0"><div class="avatar avatar-s bg-warn-50 text-warn-500"><i class="ti ti-minus"></i></div></div>';
    }

    return formattedCell;
  }

  // Format boolean true or false
  boolFormatterStatusExpire(params: any) {
    let formattedCell = '';
    
    if (params.value > moment().format('YYYY-MM-DD')) {
      formattedCell = '<div class="flex-shrink-0"><div class="avatar avatar-s bg-success-50 text-success-500"><i class="ti ti-check f-20"></i></div></div>';
    } else {
      formattedCell = '<div class="flex-shrink-0"><div class="avatar avatar-s bg-warn-50 text-warn-500"><i class="ti ti-minus"></i></div></div>';
    }

    return formattedCell;
  }

  csvExport(gridApi: any, fileName: string): void {
    var params = {
      fileName: fileName,
      columnSeparator: ';'
    };
    gridApi.exportDataAsCsv(params);
  }

  // Format date of a column
  dateFormatter(params: any) {
    if (params.value == null) {
      return null;
    }
    return moment(params.value).format('DD/MM/YYYY');
  }


  // Format date to include today for <= conditions
  dateFormatterEnd(date: any) {
    return moment(date).format('YYYY-MM-DD 23:59:59');
  }

  // Format date to include today for >= conditions
  dateFormatterStart(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  // Format date time of a column
  dateTimeFormatter(params: any) {
    if (params.value == null) {
      return null;
    }
    return moment(params.value).format('DD/MM/YYYY HH:mm');
  }

  dateTimeFormatter2(params: any) {
    return moment(params.value, 'YYYY-MM-DD\THH:mm:ssP').format('DD/MM/YYYY @HH:mm');
  }

  dateTimeObjectFormatter(params: any) {
    let d = params.value !== null && params.value !== undefined ? moment(params.value.date).format('DD/MM/YYYY HH:mm') : null;
    return d;
  }

  // Format date for DB insert
  dbDateFormatter(date: any) {
    return moment(date).format('YYYY-MM-DD\THH:mm:ssZ');
  }

  // Download base64 file from server
  downloadBase64File(url: any, name: string) {
    var link = document.createElement('a');
    link.href = url;
    link.download = name;
    link.click();
  }

  // Download from server
  downloadFile(data: any, type: string) {
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  // Download receipt
  downloadReceipt(id: any) {
    this._api.getPDFReceipt(id).subscribe(
      data => this.downloadFile(data, 'application/pdf'),
      err => console.log(err)
    );
  }

  // Generate and download receipts by manager
  generatedDownloadManualReceipt(id: any, filename: any) {
    this._api.generateReceiptPDF(id).subscribe({
      next: (response: any) => {
        this._api.downloadPDFFile('../receipts/' + filename).subscribe(
          // (response: Blob) => saveAs(response, filename)
        );
      },
      error: (error: any) => {
        console.log(error);
      }
    }
    );
  }

  // Get only changed form fields
  getChangedFormFields(fb: any, form: any) {
    const group = fb.group({});
    // For 'Update' or 'View'
    if (form.controls['id'] !== undefined) {
      group.addControl('id', form.controls['id']);
    }
    Object.keys(form.controls).forEach(name => {
      const currentControl = form.controls[name];
      if (currentControl.dirty) {
        // If is Date then convert for Database
        if (moment(currentControl.value, 'YYYY-MM-DD HH:mm:SS ', true).format() != 'Invalid date') {
          currentControl.value = this.dbDateFormatter(currentControl.value);
        }
        group.addControl(name, currentControl);
      }
    });
    return group;
  }

  // Check if agGrid element has child => cannot be deleted  => return false
  isElementDeletable(element: any) {
    let result = true;
    Object.keys(element).forEach(name => {
      if (element[name] !== null) {
        if (element[name]['id'] !== undefined || element[name]['value'] !== undefined) { // Many to One relation
          // Nothing to do for now, 
        } else if (element[name][0] !== undefined) {
          Object.keys(element[name]).forEach(childId => { // Many to Many relation
            if (element[name][childId]['id'] !== undefined || element[name][childId]['value'] !== undefined) {
              result = false;
            }
          });
        }
      }
    });
    return result;
  }

  // Check valid formControls eg: required
  isFormValid(form: any) {
    let result: any = true;

    Object.keys(form.controls).forEach(name => {
      const formControl = form.controls[name];
      if (formControl.hasError('required')) {
        formControl.markAsTouched();
        result = false;
      }
      if (formControl.hasError('email') || formControl.hasError('pattern')) {
        formControl.markAsTouched();
        result = false;
      }
    });

    if (result === false) {
      this.showNotification(this._translate("swal.missingFields"));
    }
    return result;
  }

  // Concat lastname & firstname
  lastnameFirstname(params: any) {
    let result = params.data.username;
    if(params.data.lastname != '') {
      result = params.data.firstname + ' ' + params.data.lastname; 
    }

    return result;
  }

  // Loader for slow networks
  setLoading(isLoad: boolean) {
    const loading = document.getElementById("loading");
    if(loading) {
      if(isLoad == true) {
        loading.style.visibility = "visible";
      } else {
        loading.style.visibility = "hidden";
      }
    }
  }

  // show snack bar notification
  showNotification(message: any) {
    this._snackBar.open(message, 'Close', {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: this.durationInSeconds * 1000
    });
  }

  // Format status
  statusFormatter(params: any) {
    const status = { 'color': 'primary', 'label': 'New' };

    if (params.value == 'ABN' || params == 'ABN') { status.color = 'bg-abn'; status.label = 'Cancelled'; }
    if (params.value == 'FIN' || params == 'FIN') { status.color = 'bg-fin'; status.label = 'Completed'; }
    if (params.value == 'NEW' || params == 'NEW') { status.color = 'bg-new'; status.label = 'New'; }
    if (params.value == 'RUN' || params == 'RUN') { status.color = 'bg-run'; status.label = 'In Progress'; }
    if (params.value == 'MAN' || params == 'MAN') { status.color = 'bg-man'; status.label = 'Manual'; }
    if (params.value == 'ASG' || params == 'ASG') { status.color = 'bg-asg'; status.label = 'Assigned'; }
    if (params.value == 'ACA' || params == 'ACA') { status.color = 'bg-aca'; status.label = 'Awaiting Approval'; }
    if (params.value == 'POD' || params == 'POD') { status.color = 'bg-pod'; status.label = 'Parts on Order'; }
    if (params.value == 'CLS' || params == 'CLS') { status.color = 'bg-cls'; status.label = 'Closed'; }

    const formattedCell = '<div class="status-label"><span class="sub-head ' + status.color + '">' + status.label + '</span></div>';

    return formattedCell;
  }

  // Sum property value of an array
  sumPropertyValue = (items: any, prop: any) => items.reduce((a: any, b: any) => a + b[prop], 0);

  // Format time of a column
  timeFormatter(params: any) {
    return moment(params.value).format('HH:mm');
  }

  todayIncludedFormatter(date: any) {
    return moment(date).format('YYYY-MM-DD\T23:59:59+04:00');
  }

  translateValue(params: any) {
    if(params.value != null) {
      return params.context.componentParent._gt._translate(params.value);
    } else {
      return null;
    }
  }

  viewDocument(folder: any, fileName: any) {
    let html = '<div class="row">';
    // let photo = environment.serverURL + environment.documentPath + fileName;
    let request = {
      folder: folder,
      filename: fileName
    }
    this._api.post('/api/downloadSecuredDocuments', request).subscribe({
      next: (result: any) => {
        let photo = result ? result['data']['url'] : null;
        html += '<div class=""><iframe class="swal-ged-iframe" src="' + photo + '"></iframe></div>';
        html += '</div>';
        swal.fire({
          html: html,
          showCloseButton: true,
          showConfirmButton: false,
          width: '80em',
        });
      },
      error: (err: any) => {
        console.log(err);
      }
    });

  }

  // Date filtering in ag-grid after value formatting
  dateFilterParams = {
    // provide comparator function
    // comparator: function (filterLocalDateAtMidnight: any, cellValue: any) {
    //   //using moment js
    //   var dateAsString = moment(cellValue.date).format('DD/MM/YYYY');
    //   var dateParts = dateAsString.split("/");
    //   var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

    //   if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
    //     return 0
    //   }

    //   if (cellDate < filterLocalDateAtMidnight) {
    //     return -1;
    //   }

    //   if (cellDate > filterLocalDateAtMidnight) {
    //     return 1;
    //   }
    // }
  };
  

  abilityActionList = [
    { 'id': 'manage', 'label': 'Manage' },
    { 'id': 'create', 'label': 'Create' },
    { 'id': 'read', 'label': 'Read' },
    { 'id': 'update', 'label': 'Update' },
    { 'id': 'delete', 'label': 'Delete' },
    { 'id': 'print', 'label': 'Print' }
  ];


  inputTypeList = [
    { 'id': 'CB', 'label': 'Checkbox'},
    { 'id': 'TB', 'label': 'Textbox'},
  ];


  // status list
  statusList = [
    { 'id': 'NEW', 'label': 'New'},
    { 'id': 'RUN', 'label': 'Running'},
    { 'id': 'FIN', 'label': 'Finished'},
    { 'id': 'ABN', 'label': 'Cancelled'},
    { 'id': 'CLS', 'label': 'Closed' },
    { 'id': 'ASG', 'label': 'Assigned'},
  ]
}

