// Angular import
import { Component, Input, OnInit } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';

// project import
import { NavigationItem } from 'src/app/@theme/types/navigation';
import { CrudService } from 'src/app/core/services/crud.service';
import { AuthenticationService } from 'src/app/@theme/services/authentication.service';

@Component({
  selector: 'app-compact-menu',
  templateUrl: './compact-menu.component.html',
  styleUrls: ['./compact-menu.component.scss']
})
export class CompactMenuComponent implements OnInit {
  // public props
  // @Input() menus: NavigationItem[];
  menus: any = [];
  ability: any;

  // Constructor
  constructor(
    private location: Location,
    private locationStrategy: LocationStrategy,
    private _crud: CrudService,
    private _auth: AuthenticationService
  ) {
    this.ability = this._auth.getAbility();
    this._crud.getPromise('/api/getMenu').then((res: any) => {
      let dataMenu: any = [];
      res['data'].forEach((element : any) => {
        if (this.ability.can('read', element.name.toUpperCase()) || this.ability.can('update', element.name.toUpperCase())) {
          dataMenu.push(element);
        }
      });
      this.menus = [
        {
          id: 'navigation',
          title: 'Navigation',
          type: 'group',
          icon: 'icon-navigation',
          children: dataMenu
        }
      ]
    });
  }

  ngOnInit(): void {
      
  }

  // public method
  fireOutClick() {
    let current_url = this.location.path();
    const baseHref = this.locationStrategy.getBaseHref();
    if (baseHref) {
      current_url = baseHref + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent?.parentElement?.parentElement;
      const last_parent = up_parent?.parentElement;
      if (parent?.classList.contains('coded-hasmenu')) {
        parent.classList.add('coded-trigger');
        parent.classList.add('active');
      } else if (up_parent?.classList.contains('coded-hasmenu')) {
        up_parent.classList.add('coded-trigger');
        up_parent.classList.add('active');
      } else if (last_parent?.classList.contains('coded-hasmenu')) {
        last_parent.classList.add('coded-trigger');
        last_parent.classList.add('active');
      }
    }
  }
}
