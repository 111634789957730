/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // Store the value
  set(storageKey: string, value: any) {
    const encryptedValue = window.btoa(encodeURIComponent(JSON.stringify(value)));
    localStorage.setItem(storageKey, encryptedValue);
  }

  // Get the value
  get(storageKey: string) {
    const ret = localStorage.getItem(storageKey);
    if (!ret) {
      return null;
    }
    return JSON.parse(decodeURIComponent(window.atob(ret)));
  }

  // Remove
  remove(storageKey: string) {
    localStorage.removeItem(storageKey);
  }

  // Clear storage
  clear() {
    localStorage.clear();
  }
}
