<mat-toolbar
  class="pc-header"
  [ngClass]="{
    'header-blur': HeaderBlur,
    'dev-mode': devMod
  }"
>
  <div class="header-wrapper">
    <app-nav-left></app-nav-left>
    <app-nav-right (HeaderBlur)="this.HeaderBlur = !this.HeaderBlur"></app-nav-right>
  </div>
</mat-toolbar>
