<footer class="theme-footer">
  <div class="footer-wrapper">
    <div class="flex align-item-center">
      <div class="col m-y-5">
        <p class="m-0"
          >RETINA CRM crafted by <a href="https://novotecs.com" target="_blank"><span class="text-primary-500 f-w-600">Novotecs LTD</span></a>
        </p>
      </div>
      <div class="col-auto">
        {{version}}
        <!-- <ul class="list-inline footer-link m-b-0">
          <li class="list-inline-item"><a href="https://ableproadmin.com/">Home</a></li>
          <li class="list-inline-item"><a href="https://phoenixcoded.gitbook.io/able-pro/v/angular" target="_blank">Documentation</a></li>
          <li class="list-inline-item"><a href="https://phoenixcoded.authordesk.app/" target="_blank">Support</a></li>
        </ul> -->
      </div>
    </div>
  </div>
</footer>
