import { HttpClient, provideHttpClient } from "@angular/common/http";
import { ApplicationConfig } from "@angular/core";
import { provideAnimations } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

// export function HttpLoaderFactory(http: HttpClient) {
//   return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

// export const appConfig: ApplicationConfig = {
//   providers: [provideAnimations(), provideHttpClient(),
//     TranslateModule.forRoot({
//       defaultLanguage: 'en',
//       loader: {
//         provide: TranslateLoader,
//         useFactory: HttpLoaderFactory,
//         deps: [HttpClient]
//       }
//     }).providers!]
// };

export class AbleProConfig {
  static layout = 'compact'; // vertical, horizontal, compact
  static layout_type = 'light-mode'; // light-mode, dark-mode, auto
  static theme_color = 'blue-theme'; // blue-theme, indigo-theme, purple-theme, pink-theme, red-theme, orange-theme, yellow-theme, green-theme, teal-theme, cyan-theme
  static isRtlLayout = false; // false true
  static isBox_container = false; // false true
  static theme_contrast = false; //true false
  static menu_caption = true; // true false
  static isLanding = true; // true false
}

