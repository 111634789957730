<div class="header-button">
  <button mat-button [matMenuTriggerFor]="user" aria-label="mode" class="header-item">
    <a class="pc-head-link">
      <img src="{{profileImage}}" alt="user-image" class="user-avatar" />
    </a>
  </button>
  <mat-menu #languages="matMenu" xPosition="before">
    <button mat-menu-item>
      <div class="mat-body" (click)="useLanguage('en')">English (uk)</div>
    </button>
    <button mat-menu-item>
      <div class="mat-body" (click)="useLanguage('fr')">français</div>
    </button>
    <button mat-menu-item>
      <div class="mat-body" (click)="useLanguage('ro')">Română</div>
    </button>
    <button mat-menu-item>
      <div class="mat-body" (click)="useLanguage('cn')">中国人</div>
    </button>
  </mat-menu>
  <mat-menu #setting="matMenu" xPosition="before">
    <button mat-menu-item>
      <div class="dropdown-item">
        <i class="ti ti-user"></i>
        <span>My Account</span>
      </div>
    </button>
    <button mat-menu-item>
      <div class="dropdown-item">
        <i class="ti ti-settings"></i>
        <span>Settings</span>
      </div>
    </button>
    <button mat-menu-item>
      <div class="dropdown-item">
        <i class="ti ti-headset"></i>
        <span>Support</span>
      </div>
    </button>
    <button mat-menu-item>
      <div class="dropdown-item">
        <i class="ti ti-lock"></i>
        <span>Lock Screen</span>
      </div>
    </button>
    <button mat-menu-item>
      <div class="dropdown-item">
        <i class="ti ti-power"></i>
        <span>Logout</span>
      </div>
    </button>
  </mat-menu>
  <mat-drawer #drawer position="end" class="notification-status sidenav" mode="over">
    <div class="drawer-header">
      <div class="f-16 f-w-600">What’s new announcement?</div>
      <button type="button" mat-icon-button class="close-drawer" (click)="headerBlur(); drawer.toggle()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-scrollbar style="height: calc(100vh - 88px)" visibility="hover">
      <div class="drawer-body">
        <div class="m-b-10 mat-body f-w-500">Today</div>
        <app-card [showHeader]="false" padding="16" cardClass="m-b-15">
          <div class="flex align-item-center m-b-15">
            <div class="mat-subtitle-2 badge-status bg-success-50 text-success-500 m-r-10">New Feature</div>
            <p class="mat-caption m-b-0 m-r-10 text-muted">Just Now</p>
            <span class="fas fa-circle f-8 text-suucess-500"></span>
          </div>
          <div class="mat-body f-w-600 m-b-15">Select Business Unit</div>
          <p class="mat-body text-muted text-wrap">
            You can use the Analytics Dashboard to explore how many new users download reports daily and monthly
          </p>
          <img class="m-b-15 img-fluid" src="assets/images/layout/img-announcement-1.png" alt="img" />
          <div class="row">
            <div class="col-md-6">
              <div class="grid">
                <button mat-stroked-button class="mat-body f-w-500">Skip Intro</button>
              </div>
            </div>
            <div class="col-md-6">
              <div class="grid">
                <button mat-flat-button color="primary" class="mat-body f-w-500">Next</button>
              </div>
            </div>
          </div>
        </app-card>
        <app-card [showHeader]="false" padding="16" cardClass="m-b-15">
          <div class="flex align-item-center m-b-15">
            <div class="mat-subtitle-2 badge-status bg-warning-50 text-warning-500 m-r-10">Meeting</div>
            <p class="mat-caption m-b-0 m-r-10 text-muted">2 min ago</p>
            <span class="fas fa-circle f-8 text-warning-500"></span>
          </div>
          <div class="m-b-15 mat-body f-w-600">General Meeting for update</div>
          <p class="mat-body text-muted text-wrap">
            You can use the Analytics Dashboard to explore how many new users download reports daily and monthly
          </p>
          <img class="img-fluid" src="assets/images/layout/img-announcement-2.png" alt="img" />
        </app-card>
        <div class="m-b-10 mat-body f-w-500">Yesterday</div>
      </div>
    </ng-scrollbar>
  </mat-drawer>
  <mat-menu #Notification="matMenu" xPosition="before" class="dropdown-notification">
    <div class="dropdown-header flex align-item-center justify-content-between">
      <div class="f-w-600 f-16">Notification</div>
      <a href="javascript:" class="mat-body text-primary-500">Mark all read</a>
    </div>
    <ng-scrollbar style="height: calc(100vh - 215px)" visibility="hover">
      <div class="dropdown-body">
        <div class="m-b-10 mat-body f-w-500">Today</div>
        <div class="m-b-10 mat-body f-w-500">Yesterday</div>
        <app-card [showHeader]="false" cardClass="notification-card m-b-10" padding="16">
          <div class="flex">
            <div class="topic-icon">
              <svg class="pc-icon text-primary-500">
                <use xlink:href="assets/fonts/custom-icon.svg#custom-user-bold"></use>
              </svg>
            </div>
            <div class="topic-description m-l-15">
              <span class="mat-caption float-end text-muted">15 hour ago</span>
              <div class="m-b-10 mat-body f-w-600">Challenge invitation</div>
              <p class="m-b-2 mat-body">Jonny aber invites to join the challenge</p>
              <button mat-stroked-button class="m-r-10">Decline</button>
              <button mat-raised-button color="primary">Accept</button>
            </div>
          </div>
        </app-card>
      </div>
    </ng-scrollbar>
  </mat-menu>
  <mat-menu #user="matMenu" xPosition="before" class="dropdown-user-profile">
    <div class="dropdown-header flex align-item-center justify-content-between">
      <div class="user-title f-16 f-w-600">Profile</div>
    </div>
    <ng-scrollbar style="height: calc(100vh - 225px)" visibility="hover">
      <div class="dropdown-body">
        <div class="flex m-b-5">
          <div class="topic-icon">
            <img src="{{profileImage}}" alt="user-images" class="user-avatar" />
          </div>
          <div class="topic-description m-l-15">
            <h6 class="user-name">{{currentUser?.firstname}} {{currentUser?.lastname}}</h6>
            <p class="mat-body">{{currentUser?.email}}</p>
          </div>
        </div>
        <mat-divider class="m-y-15"></mat-divider>
        <div class="m-b-15 mat-body f-w-500">Manage</div>
        <!-- <a href="javascript:" class="dropdown-item text-muted">
          <svg class="pc-icon m-r-10">
            <use xlink:href="assets/fonts/custom-icon.svg#custom-setting-outline"></use>
          </svg>
          <div class="m-b-0 mat-body">Settings</div>
        </a>
        <a href="javascript:" class="dropdown-item text-muted">
          <svg class="pc-icon m-r-10">
            <use xlink:href="assets/fonts/custom-icon.svg#custom-share-bold"></use>
          </svg>
          <div class="m-b-0 mat-body">Share</div>
        </a> -->
        <a href="javascript:" class="dropdown-item text-muted" (click)="modalPassword()">
          <svg class="pc-icon m-r-10">
            <use xlink:href="assets/fonts/custom-icon.svg#custom-lock-outline"></use>
          </svg>
          <div class="m-b-0 mat-body">Change Password</div>
        </a>
        <mat-divider class="m-b-15 m-t-15"></mat-divider>
        
        <mat-divider class="m-b-15 m-t-15"></mat-divider>
        <div class="grid m-b-15">
          <button mat-flat-button color="warn" class="user-logout" (click)="logout()">
            <div class="flex-inline align-item-center">
              <svg class="pc-icon m-r-10">
                <use xlink:href="assets/fonts/custom-icon.svg#custom-logout-1-outline"></use>
              </svg>
              Logout
            </div>
          </button>
        </div>
      </div>
    </ng-scrollbar>
  </mat-menu>
</div>
