/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/core/services/api.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { Ability, AbilityBuilder } from '@casl/ability';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // eslint-disable-next-line
  private authURL = environment.serverURL + environment.authURI;
  currentUser: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    private _api: ApiService,
    private _storage: StorageService,
    private ability: Ability
  ) {
  }

  login(username: string, password: string) {
    const httpHeaders = new HttpHeaders();
    httpHeaders.append('content-type', 'application/x-www-form-urlencoded');
    httpHeaders.append('Access-Control-Allow-Origin', environment.apiUrl);
    httpHeaders.append('Access-Control-Allow-Method', 'POST');
    // Request headers you wish to allow
    httpHeaders.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

    const options = {
      headers: httpHeaders
    }

    const body = new FormData();
    //let body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('client_id', environment.CLIENT_ID);
    body.set('client_secret', environment.CLIENT_SECRET);
    body.set('grant_type', 'password');
    
    return this.http.post(this.authURL, body, options).pipe(map((response) => {
        this._storage.set('currentToken', response);
      })
    );
  }

  public getToken(){
    const currentToken = this._storage.get('currentToken');
    if (currentToken) {
       return currentToken.access_token;
    }

    return null;
  }

  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting 
    // whether or not the token is expired
    if (token) {
      return true;
    }
    return false;
  }

  public setCurrentUser(currentUser: any) {
    this._api.getData('/api/getCurrentUserData').subscribe((response: any) => {
      
      this.currentUser = response['data'][0] !== undefined ? response['data'][0] : null;
        // Save currentUser to localstorage
      this._storage.set('currentUser', this.currentUser);
      // Assign the permissions
      this.updateAbility(this.currentUser);

      if (this.currentUser.roles[0] == 'CLIENT') {
        this.router.navigate(['/clients/job']);
      } else {
        this.router.navigate(['/dashboard']);
      }

    });
  }

  getCurrentUser() {
    if (this.currentUser === undefined) {
      this.currentUser = this._storage.get('currentUser');
      if (this.currentUser) {
        this.updateAbility(this.currentUser);
      } else {
        if (!this.router.url.includes('forgot-password') && !this.router.url.includes('new-password')) {
          this.logout();
        }
      }
    }
    return this.currentUser;
  }

  getAbility() {
    return this.ability;
  }

  private updateAbility(user: any) {

    const { can, cannot, rules } = new AbilityBuilder(Ability);
    const permissions = user.permissions;

    if (permissions !== undefined && permissions !== null) {
      permissions.forEach((element: any) => {

        if (element.subject.toLowerCase() == 'all') {
          element.subject = element.subject.toLowerCase();
        }
        if (element.ability == 'can') { // CAN
          can(element.ability_action, element.subject);
        } else { // CANNOT
          cannot(element.ability_action, element.subject);
        }
      })

      this.ability.update(rules);
    }

    if (user.roles.includes('SUPER_ADMIN')) {
      can('manage', 'all');
      this.ability.update(rules);
    } else {
      cannot('manage', 'ROLE');
      cannot('manage', 'MODULENAME');
      cannot('manage', 'ADVANCED');
      this.ability.update(rules);
    }
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentToken');
    this.ability.update([]);
    this.router.navigate(['/authentication/login']);
  }
}
