<app-card cardTitle="Change password" [formGroup]="form">
    <ng-template #headerOptionsTemplate>
        <div>
            <button mat-raised-button color="primary" class="mr-5" (click)="save()"><mat-icon>done</mat-icon> Save</button>
        <button mat-raised-button mat-dialog-close color="warn"><mat-icon>close</mat-icon> Close</button>
        </div>
    </ng-template>
    <mat-form-field appearance="outline" class="w-100 m-t-5">
        <mat-label>Password</mat-label>
        <input
            [type]="hide ? 'password' : 'text'"
            matInput
            formControlName="password"
            placeholder="Password"
            [ngClass]="{ 'is-invalid': f?.['password'].errors }"
        />
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100 m-t-5">
        <mat-label>Confirm password</mat-label>
        <input
            [type]="hide2 ? 'password' : 'text'"
            matInput
            formControlName="confirm_password"
            placeholder="Confirm password"
            [ngClass]="{ 'is-invalid': f?.['confirm_password'].errors }"
        />
        <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
            <mat-icon>{{ hide2 ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
    </mat-form-field>
</app-card>
