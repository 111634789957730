<ng-scrollbar style="height: calc(100vh - 88px)" visibility="hover">
  @if (showContent) {
    <app-card [showHeader]="false" cardClass="user-card" padding="20">
      <div class="flex align-items-center">
        <div class="flex-shrink-0">
          <img src="assets/images/user/user-retina.png" alt="user-image" class="user-avatar" />
        </div>
        <div class="flex-grow-1 user-details">
          <div class="f-w-600">{{currentUser?.firstname}} {{currentUser?.lastname}}</div>
          <div class="mat-caption">{{role}}</div>
        </div>
      </div>
    </app-card>
  }
  <div class="navbar-content">
    <ul class="nav coded-inner-navbar" (click)="fireOutClick()">
      @for (item of menus; track item.id) {
        @if (item.type === 'group') {
          <app-menu-group-vertical [item]="item"></app-menu-group-vertical>
        } @else if (item.type === 'collapse') {
          <app-menu-collapse [item]="item"></app-menu-collapse>
        } @else if (item.type === 'item') {
          <app-menu-item [item]="item"></app-menu-item>
        }
      }
    </ul>
  </div>
</ng-scrollbar>
