<div class="header-button">
  @if (showToggleMenu) {
    <button mat-button class="header-item sidebar-collapse" (click)="toggleMenu()">
      <a class="pc-head-link">
        <i class="ti ti-menu-2"></i>
      </a>
    </button>
  }
  @if (showLogo) {
    <span>
      <span class="long-logo">
        <img src="assets/images/logo/petroserv-logo.png" alt="user-image" class="logo-retina-long" />
      </span>
    </span>
  }
  <!-- <button mat-button [matMenuTriggerFor]="menu" class="header-item" aria-label="search">
    <a class="pc-head-link">
      <svg class="pc-icon">
        <use xlink:href="assets/fonts/custom-icon.svg#custom-search-normal-1"></use>
      </svg>
    </a>
  </button> -->
  <mat-menu #menu="matMenu" class="dropdown-item-search">
    <mat-form-field class="w-100">
      <input matInput (click)="$event.stopPropagation()" placeholder="Search here. . ." />
    </mat-form-field>
  </mat-menu>
</div>
