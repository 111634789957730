/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private apiUrl = environment.serverURL + '/api';
  private entityUrl = environment.serverURL + environment.entityURI;
  private uploadImgURI = environment.serverURL + environment.uploadPath;

  constructor(private httpClient: HttpClient) { }

  // Get
  getData(uri: any, options?: any) {
    return this.httpClient.get(environment.apiUrl + uri, options)
      .pipe(
        // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  // Post any URI -> /api/etc/etc
  post(uri: any, data: any) {
    return this.httpClient.post(environment.apiUrl + uri, JSON.stringify(data))
      .pipe(
        // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  // Put
  put(uri: any, data: any) {
    return this.httpClient.put(environment.apiUrl + uri, JSON.stringify(data))
      .pipe(
        // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  // Delete
  delete(uri: any) {
    return this.httpClient.delete(environment.apiUrl + uri)
      .pipe(
        // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }

  // Example: /api/getDailyLogSheets/2019-04-19
  public getDailyLogSheets(logDate: any) {
    return this.httpClient.get(this.apiUrl + '/getDailyLogSheets/' + logDate)
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  // Generate daily log sheets
  public generateDailyLogSheets(logDate: any) {
    return this.httpClient.get(this.apiUrl + '/generateDailyLogSheets/' + logDate)
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  // Download pdf file with relative path
  // example : ../receipts/IOML_MK84234_000006_20181212.pdf
  public downloadPDFFile(fullname: string) {
    const filename = { "fullname": fullname };
    return this.httpClient.post(this.apiUrl + '/downloadFile', JSON.stringify(filename), { responseType: 'blob' })
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }


  public downloadExcelFile(fullname: string) {
    let filename = { "fullname": fullname };
    return this.httpClient.post(this.apiUrl + '/downloadExcelFile', JSON.stringify(filename))
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  // Generate manual Receipt
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public generateReceiptPDF(id: any) {
    const content = {};
    return this.httpClient.post(this.apiUrl + '/generateReceiptPDF/' + id, JSON.stringify(content))
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  // Print pdf generated receipt
  public getPDFReceipt(id: number) {
    const url = environment.serverURL + "/api/downloadReceiptFile/" + id;
    return this.httpClient.get(url, { responseType: 'arraybuffer' }).pipe(
      // retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleError(_error: HttpErrorResponse) {
    return throwError('Something bad happened; please try again later.');
  }
}

