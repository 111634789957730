import { environment as defaultEnvironment } from './environment.defaults';
import packageInfo from '../../package.json';

export const environment = {
  ...defaultEnvironment,

  appVersion: packageInfo.version,
  production: false,
  apiUrl: 'https://dev02-retinacrm.novotecs.com',
  serverURL: 'https://dev02-retinacrm.novotecs.com',
};